import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/withRouter';
import { StaticPage } from './Helper';
import { view } from '../actions/page';
import PropTypes from 'prop-types'

class PageComponent extends Component {

    constructor(props) {
        super(props);
        const page = props.hasOwnProperty("page") && props.page != undefined ? props.page : '';
        this.state = {
            page: props.location.pathname.toLowerCase().replace("/", ""),
        };
    }

    componentDidMount() {
        if(!this.props.isServer){
            this.handleFetch();
        }else{
            this.props.clearServerLoad();
        }
    }

    componentWillUnmount() {
        this.props.clearPage();
        this.props.clearAlerts();
    }

    componentDidUpdate(prevProps, prevState) {
        let cloneCurState = { ...this.state }
        let clonePreState = { ...prevState }
        cloneCurState.showloader = false;
        clonePreState.showloader = false;
        if (JSON.stringify(cloneCurState).toLowerCase().trim() != JSON.stringify(clonePreState).toLowerCase().trim()) {
            this.handlePageChange();
            this.handleFetch();
        }else if (JSON.stringify({pathname: prevProps.location.pathname, search: prevProps.location.search}).toLowerCase().trim() !== JSON.stringify({pathname: this.props.location.pathname, search: this.props.location.search}).toLowerCase().trim()) {
            this.check_for_state_change(this.props);
        }
    }

    check_for_state_change(props) {
        const page = props.location.pathname.toLowerCase().replace("/", "");
        let newState = {};
        if(page !== this.state.page){
            newState.page = page;
        }        
        if (Object.keys(newState).length > 0) {
            this.setState(newState);
        }
    }

    handlePageChange() {
        this.props.history.push({
            pathname: this.props.location.pathname
        });
    }

    handleFetch() {
        this.props.clearAlerts();
        this.props.getPage(this.state.page,this.props.influencer, this.props.token);
    }   

    render() {
        return (
          <StaticPage
            title={this.props.title}
            content={this.props.content}
          />
        );
      }
}

PageComponent.contextTypes = {
    router: PropTypes.object.isRequired
}

PageComponent.propTypes = {
    location: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => {
    return {
        token: state.auth.token,
        influencer: state.influencer ? state.influencer : {},
        title: state.page.title,
        content: state.page.content,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPage: async (slug, influencer = {}, token = null) => {
            await dispatch(view(slug, influencer, token));
        },
        clearPage: () => {
            dispatch({ type: 'CLEAR_PAGE' });
        },
        clearServerLoad: () => {
            dispatch({ type: 'SERVER_LOAD_END' });
        },         
        clearAlerts: () => {
            dispatch({ type: 'CLEAR_ALERTS' });
        }
    }
}
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageComponent));

